import moment from "moment";
import "moment/locale/nl";
import i18n from "i18next";
import IntervalPlural from "i18next-intervalplural-postprocessor";

import nl from "./locales/nl";
import en from "./locales/en";

i18n.use(IntervalPlural).init({
  fallbackLng: "nl",
  ns: ["common", "home"],
  defaultNS: "common",

  resources: {
    nl,
    en,
  },

  debug: process.env.NODE_ENV === "development",

  interpolation: {
    escapeValue: false,
  },

  react: {
    wait: true,
  },
});

moment.updateLocale("nl", { week: { dow: 1 } });
moment.updateLocale("en", { week: { dow: 1 } });

export default i18n;
