import { useState } from "react";

const getLocalStorage = (key, fallback) => {
  if (typeof localStorage === "undefined") {
    return fallback;
  }
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : fallback;
};

const setLocalStorage = (key, value) =>
  typeof localStorage === "undefined"
    ? localStorage.setItem(key, JSON.stringify(value))
    : undefined;

const useLocallyStoredState = ({ key, initialValue }) => {
  const [state, _setState] = useState(getLocalStorage(key, initialValue));
  const setState = (v) =>
    _setState((state) => {
      const newState = typeof v === "function" ? v(state) : v;
      setLocalStorage(key, newState);
      return newState;
    });

  return [state, setState];
};

export default useLocallyStoredState;
