import React from "react";
import ColorModeProvider from "containers/ColorModeProvider";
import LocaleProvider from "containers/LocaleProvider";

export const wrapRootElement = ({ element }) => (
  <ColorModeProvider>{element}</ColorModeProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <LocaleProvider {...props}>{element}</LocaleProvider>
);
