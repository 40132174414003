import blog from "./blog.json";
import common from "./common.json";
import home from "./home.json";
import navigation from "./navigation.json";
import not_found from "./not_found.json";

export default {
  blog,
  common,
  home,
  navigation,
  not_found,
};
