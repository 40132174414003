import moment from "moment";
import i18n from "../i18n";

import React, { useLayoutEffect, useState } from "react";
import { TranslationContext } from "../contexts";

export default function LocaleProvider({ children, pageContext: { locale } }) {
  const [state, setState] = useState("notLoaded");

  useLayoutEffect(() => {
    const loadLocale = async () => {
      setState("loading");
      await i18n.changeLanguage(locale);
      moment.locale(locale);
      setState("loaded");
    };
    if (i18n.language !== locale && state !== "loading") {
      loadLocale();
    }
  }, [locale, state]);

  return state === "loaded" ? (
    <TranslationContext.Provider
      value={{
        i18n,
        moment,
      }}
    >
      {children}
    </TranslationContext.Provider>
  ) : null;
}
