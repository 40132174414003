import React, { useLayoutEffect } from "react";
import { ColorModeContext } from "../contexts";
import useColorMode from "hooks/useColorMode";

export default function ColorModeProvider({ children }) {
  const value = useColorMode();

  useLayoutEffect(() => {
    const colorMode = value[0];
    document.body.classList.toggle("dark", colorMode === "dark");
    document.body.classList.toggle("light", colorMode === "light");
  }, [value]);

  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
}
